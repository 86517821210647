import axios from 'axios'
import { axiosEdu } from '@/axios/axios'

export function setHeaderToken (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}

export function removeHeaderToken () {
  delete axios.defaults.headers.common.Authorization
}

export function setHeaderTokenEdu (token) {
  axiosEdu.defaults.headers.common.Authorization = 'Bearer ' + token
  axiosEdu.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}

export function removeHeaderTokenEdu () {
  delete axiosEdu.defaults.headers.common.Authorization
}
