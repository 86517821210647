export default {
  "main": {
    "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " List"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Baru ", _interpolate(_list(0))])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Aktif"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
    "datanotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Tidak Ditemukan"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
    "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create ", _interpolate(_list(0))])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Edit ", _interpolate(_list(0))])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create ", _interpolate(_list(0))])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])}
  },
  "menu": {
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akademik"])},
    "nonacademic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bukan Akademik"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keuangan"])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buat ", _interpolate(_list(0))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hapus ", _interpolate(_list(0))])},
    "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " List"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Baru ", _interpolate(_list(0))])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Active"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
    "datanotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Tidak Ditemukan"])},
    "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "changePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti Kata Sandi"])},
    "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Gelap"])},
    "lightmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Terang"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari..."])},
    "searchT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih opsi"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERINGATAN!!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersihkan"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selang Waktu"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halo,"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses"])}
  },
  "sidebar": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "dashboard": {
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pernyataan Bank"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi di Amatra"])}
  },
  "penalty": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Invoice per"])},
    "vacode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode perusahaan"])}
  },
  "generateva": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beberapa siswa / calon siswa belum memiliki Nomor VA. Silakan lengkapi data sebelum Anda memproses tagihan."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silakan unduh data sebagai rujukan"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tautan unduhan"])}
  },
  "sidebarFin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "coa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
    "finYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Buku"])},
    "tempEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
    "typeIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
    "genPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitung Denda"])},
    "genVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Tagihan VA"])},
    "upVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah VA"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Siaran"])},
    "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal"])},
    "genJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal Umum"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
    "typeJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal"])},
    "outbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak Keluar"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak Masuk"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran"])},
    "waiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver"])},
    "vastudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Siswa"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan"])},
    "arsummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringkasan Sisa Bayar AR"])},
    "financialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Keuangan"])},
    "paymentsummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringkasan Pembayaran"])},
    "paymentsummaryclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdasarkan Jurusan dan Kelas"])},
    "paymentsummarytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdasarkan Jenis Jurnal"])},
    "invoicetransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan"])},
    "paymenttransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumen"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan"])},
    "optionsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pilihan"])},
    "reviewercomposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komposisi Peninjau"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator"])},
    "indicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran Indikator"])}
  },
  "indicatortarget": {
    "indicator_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator"])},
    "target_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sasaran"])},
    "target_uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran UOM"])},
    "consider_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertimbangkan Qty"])},
    "consider_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertimbangkan Kualitas"])},
    "consider_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertimbangkan Waktu"])},
    "quality_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Kualitas"])},
    "quality_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Kualitas"])},
    "time_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Waktu"])},
    "combine_score_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menggabungkan Metode Skor"])},
    "qty_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Persen"])},
    "quality_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kualitas Pct"])},
    "time_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Persen"])},
    "addindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Indicator Target"])},
    "editindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Indicator Target"])},
    "detailindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Target Detail"])}
  },
  "indicator": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "position_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumen"])},
    "indicator_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Indikator"])},
    "indicator_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Induk Indikator"])},
    "indicator_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Indikator"])},
    "scoring_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi Skor"])},
    "instrument_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi Instrumen"])},
    "target_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi Sasaran"])},
    "indicator_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan Indikator"])},
    "indicator_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan Indikator"])},
    "indicator_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Indikator"])},
    "addindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Indikator"])},
    "editindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Indikator"])},
    "detailindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Indikator"])}
  },
  "reviewer": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "reviewer_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diri Peninjau"])},
    "reviewer_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekan Resensi"])},
    "reviewer_superordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau Super Ordinat"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "addreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Reviewer Composition"])},
    "editreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Reviewer Composition"])},
    "detailreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Reviewer Composition "])}
  },
  "instrument": {
    "instrument_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Instrumen"])},
    "instrument_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Instrumen"])},
    "instrument_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber Instrumen"])},
    "instrument_scoring_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode Penilaian Instrumen"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat"])},
    "reviewer_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diri Peninjau"])},
    "reviewer_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekan Resensi"])},
    "reviewer_super_ordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau Super Ordinat"])},
    "addInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Instrumen"])},
    "editInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Instrumen"])},
    "detailInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Instrumen"])}
  },
  "regular": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Pelajaran Regular"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Ujian"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Ujian"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Ujian"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetensi Dasar"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaikan"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes Asli"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan Nilai"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata - Rata Siswa"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Akhir"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbangan"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitung dan Simpan Nilai Akhir"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhitungan"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah anda yakin akan menghitung nilai akhir secara otomatis,semua nilai yang telah dihitung sebelumnya akan dihitung ulang. lanjutkan?"])},
    "addRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Regular"])},
    "editRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Regular"])},
    "detailRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Regular"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 2"])}
  },
  "parallel": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Pelajaran Paralel"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Ujian"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Ujian"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Ujian"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetensi Dasar"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaikan"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes Asli"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan Nilai"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata - Rata Siswa"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Akhir"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbangan"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitung dan Simpan Nilai Akhir"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhitungan"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah anda yakin akan menghitung nilai akhir secara otomatis,semua nilai yang telah dihitung sebelumnya akan dihitung ulang. lanjutkan?"])},
    "addParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Paralel"])},
    "editParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Paralel"])},
    "detailParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Paralel"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 2"])}
  },
  "ledger": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Pelajaran Regular"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Ujian"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Ujian"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Ujian"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetensi Dasar"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaikan"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes Asli"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan Nilai"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata - Rata Siswa"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Akhir"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbangan"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitung dan Simpan Nilai Akhir"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhitungan"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah anda yakin akan menghitung nilai akhir secara otomatis,semua nilai yang telah dihitung sebelumnya akan dihitung ulang. lanjutkan?"])},
    "addLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Ruang Tamu Buku Besar"])},
    "editLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Ruang Tamu Buku Besar"])},
    "detailLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Ruang Tamu Buku Besar"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar Wali Kelas 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar Wali Kelas 2"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran"])},
    "underkkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Di Bawah KKM"])},
    "averageduo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-Rata Reguler & Paralel"])},
    "regularrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat Reguler"])},
    "averageinterst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-Rata Peminatan"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata"])},
    "overall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat Keseluruhan"])},
    "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyakit"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan Izin"])},
    "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada izin"])},
    "avgskill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterampilan AVG"])},
    "avgknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengetahuan AVG"])},
    "gradeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "avgks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG"])}
  },
  "options": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "position_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumen"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "option_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Nama"])},
    "addOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pilihan"])},
    "editOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pilihan"])},
    "detailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pilihan"])}
  },
  "optionsDetail": {
    "option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan"])},
    "option_detail_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pilihan Nama"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "sorting_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyortir No"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "addOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pilihan"])},
    "editDetailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pilihan"])},
    "detailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options Detail"])}
  },
  "class": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Ajaran"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasitas"])},
    "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelombang"])},
    "homeroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wali Kelas"])},
    "homeroom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wali Kelas"])},
    "homeroom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wali Kelas 2"])},
    "homeroom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wali Kelas 3"])},
    "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "isArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsip"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan"])},
    "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi 1"])},
    "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi 2"])},
    "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi 3"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Kelas"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Kelas"])},
    "detailClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Kelas"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kode"])},
      "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tahun Ajaran"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kapasitas"])},
      "homeroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ruang Kelas"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Level"])},
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Department"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kelas"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Catatan"])},
      "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])},
      "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])},
      "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])}
    }
  },
  "subject": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajaran"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pelajaran"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Pelajaran"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok Pelajaran"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode Pembelajaran"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Ambang"])},
    "placeholder": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kode"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama"])},
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Departemen"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tipe"])},
      "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])},
      "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])},
      "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan Tentang Kelas ini"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kelompok"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Dibuat Oleh"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Diperbarui Oleh"])}
    }
  },
  "permission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan Izin"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Siswa"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Kelas"])},
    "date1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal 1"])},
    "date2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal 2"])},
    "permission_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Perizinan"])},
    "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Perizinan"])},
    "transaction_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Transaksi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah oleh"])},
    "placeholder": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Siswa"])},
      "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tipe Izin"])},
      "transaction_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tipe Transaksi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Deskripsi"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Keterangan"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan File"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Status"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    }
  },
  "vaccines": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imunisasi"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Imunisasi"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Imunisasi"])},
    "detailVaccines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Imunisasi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis vaksin"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Deskripsi"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    }
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Transaksi"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok Transaksi"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "last_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Terakhir"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebar"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Versi"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Lebar"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kode"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Domain"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    },
    "notifAddSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Berhasil Dibuat"])},
    "notifAddFailedGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Gagal Dibuat"])},
    "notifEditSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Berhasil Diubah"])},
    "notifEditFailedGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Tidak Ditemukan"])},
    "notifDeleteSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Gagal Dihapus"])},
    "notifDeleteNotFoundGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi Tidak Ditemukan"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Gagal Dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Berhasil Diubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Gagal Dihapus"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipe transaksi Tidak Ditemukan"])}
  },
  "generatepenalty": {
    "notifInvDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal tidak boleh kurang dari Tahun Keuangan"])},
    "notifInvDateMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal tidak boleh melebihi Tanggal Akhir Tahun Keuangan"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate denda berhasil"])},
    "notifNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada denda yang dihasilkan"])}
  },
  "vastudent": {
    "siswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siswa"])},
    "calonsiswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidat"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NISN"])},
    "regNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pendaftaran"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "income_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Penghasilan"])},
    "category_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "Level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas"])},
    "batch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok"])},
    "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekolah"])},
    "code_customer_va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No VA"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Berhasil dihapus."])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Tidak ditemukan."])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Berhasil dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Gagal dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Berhasil diubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Gagal diubah"])}
  },
  "role": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otoritas"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubat Oleh"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Versi"])},
      "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Otoritas"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    }
  },
  "rolegroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok Peran"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubat Oleh"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Versi"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Name"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    }
  },
  "roleuser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran User"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kelompok"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama"])}
    }
  },
  "approver": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberi Persetujuan Transaksi"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Peran"])}
    },
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Gagal Dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Berhasil Diubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Gagal Dihapus"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Tidak Ditemukan"])}
  },
  "presenceParent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presensi"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bawaan"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Kehadiran"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hadir"])},
    "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sakit"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin"])},
    "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpa"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringkasan"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor"])},
    "tardy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlambat"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to input Student AttendanceParent"])},
    "inforeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to show the report"])},
    "listInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to display Attendance List"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Anak"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mata Pelajaran"])},
    "addChildName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih nama anak"])},
    "addSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih mata pelajaran"])},
    "addMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Bulan"])},
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halo,"])},
    "alertFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih nama anak, bulan, dan mata pelajaran pada menu filter di atas!"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari"])},
    "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari"])},
    "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maret"])},
    "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "mei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mei"])},
    "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
    "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
    "agu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agustus"])},
    "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "okt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desember"])},
    "day1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin"])},
    "day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa"])},
    "day3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu"])},
    "day4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis"])},
    "day5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat"])},
    "day6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu"])},
    "day7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat Datang"])}
  },
  "candidate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidat"])},
    "formNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Form"])},
    "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NISN"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "familycardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kartu Keluarga"])},
    "familycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu Keluarga"])},
    "noun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UN Number"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebangsaan"])},
    "childOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anak Ke"])},
    "noOfSiblings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Saudara"])},
    "childStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Anak"])},
    "ortuStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Orang Tua"])},
    "jkandung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suadara Kandung"])},
    "jtiri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suadara Tiri"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jarak"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Badan"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi Badan"])},
    "blood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golongan Darah"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sejarah Kesehatan"])},
    "formerSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal Sekolah"])},
    "diplomaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Ijazah"])},
    "diplomaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Izajah"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan"])},
    "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesi"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
    "waNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Whatsapp"])},
    "guardianName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Penjaga"])},
    "kkNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kartu Keluarga"])},
    "correspondenceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence Address"])},
    "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA No"])},
    "hobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbies"])},
    "lineId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line ID"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])},
    "submenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submenu"])},
    "summary1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum#1"])},
    "summary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum#1"])},
    "scoretest1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#1"])},
    "scoretest2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#2"])},
    "scoretest3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#3"])},
    "scoretest4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#4"])},
    "scoretest5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#5"])},
    "scoretest6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#6"])},
    "scoretest7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#7"])},
    "scoretest8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#8"])},
    "scoretest9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#9"])},
    "scoretest10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#10"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Group"])},
      "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NISN"])},
      "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NIS"])},
      "familycardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Family Card Id"])},
      "noun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan UN Number"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Name"])},
      "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nickname"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Status"])},
      "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Condition"])},
      "childOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Child Order"])},
      "childStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Child Status"])},
      "ortuStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Parents Status"])},
      "jkandung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Siblings"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Language"])},
      "jtiri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Stepbrother/Stepsister"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Postal Code"])},
      "formerSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Former School"])},
      "diplomaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Diploma No"])},
      "diplomaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Diploma Date"])},
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Distance"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Weight"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Height"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Medical History"])},
      "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Income"])},
      "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan VA No"])},
      "hobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Hobby"])},
      "kkNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan No KK"])},
      "correspondenceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Correspondence Address"])},
      "lineId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Line ID"])},
      "noOfSiblings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan No Of Siblings"])},
      "summary1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Sum#1"])},
      "summary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Sum#1"])},
      "scoretest1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#1"])},
      "scoretest2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#2"])},
      "scoretest3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#3"])},
      "scoretest4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#4"])},
      "scoretest5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#5"])},
      "scoretest6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#6"])},
      "scoretest7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#7"])},
      "scoretest8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#8"])},
      "scoretest9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#9"])},
      "scoretest10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Score#10"])}
    }
  },
  "extracuricular": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstrakurikuler"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Ekstrakurikuler"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Ekstrakurikuler"])},
    "detailExtracuricular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Ekstrakurikuler"])},
    "iscompulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wajib"])},
    "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wajib"])},
    "notcompulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Wajib"])},
    "set_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Warna"])},
    "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warna Huruf"])},
    "placeholder": {
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Wajib atau Tidak"])}
    }
  },
  "registrationbatch": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelombang Pendaftaran"])},
    "addregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Pendaftaran"])},
    "editregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pendaftaran"])},
    "detailregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pendaftaran"])}
  },
  "resignationreason": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengunduran Diri"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Pengunduran"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pengunduran"])},
    "detailreason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pengunduran"])},
    "reason_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])}
  },
  "academicbatch": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelombang Akademik"])},
    "editbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Gelombang Akademik"])},
    "addbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Gelombang Akademik"])},
    "detailbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Gelombang Akademik"])}
  },
  "academicyear": {
    "addyearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tahun Ajaran"])},
    "edityearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tahun Ajaran"])},
    "detailyearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tahun Ajaran"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Ajaran"])},
    "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Berakhir"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "placeholder": {
      "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tanggal Mulai"])},
      "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tanggal Berakhir"])},
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisipan Wajib"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Academic Year"])}
    }
  },
  "employee": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegawai"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pegawai"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Pegawai"])},
    "detailEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pegawai"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagian"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panggilan"])},
    "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar"])},
    "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agama"])},
    "ethnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suku"])},
    "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIP"])},
    "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KTP"])},
    "taxIdNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Id Tax"])},
    "familyCardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kartu Keluarga"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paspor"])},
    "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Wajib Pajak"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twitter"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun Bank"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelayanan Kesehatan"])},
    "socialSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keamanan sosial"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klinik"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "workUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Kerja"])},
    "nrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Registrasi Pusat"])},
    "nuptk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Unik Pendidik dan Tenaga Kependidikan"])},
    "initialDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Awal"])},
    "finalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Akhir"])},
    "noIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor KTP"])},
    "startWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai Berkerja"])},
    "remarksNonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar Tidak Aktif"])},
    "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensiun"])},
    "doAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit"])},
    "totalrelated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Biaya Terkait Pekerjaan"])},
    "softskill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft Skill"])},
    "bloodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golongan Darah"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laki - Laki"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perempuan"])},
    "noMarried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Menikah"])},
    "haveMarried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menikah"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menikah"])},
    "noid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Identitas"])},
    "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon"])},
    "mobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Handphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat Lahir"])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Lahir"])},
    "employeePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN Pegawai"])},
    "retirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masa Pensiun"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelatihan"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akhir"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekolah"])},
    "graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulusan"])},
    "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai Tahun"])},
    "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berakhir Tahun"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaji"])},
    "placeholder": {
      "initialDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gelar Awal"])},
      "finalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gelar Akhir"])},
      "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Bagian"])},
      "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Panggilan"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gelar"])},
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Agama"])},
      "ethnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Suku"])},
      "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan NIP"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Jenis Kelamin"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Status Menikah"])},
      "noid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan No Identitas"])},
      "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan No. Telp"])},
      "mobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan No. Handphone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Email"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Foto"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat"])},
      "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tempat Lahir"])},
      "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tanggal Lahir"])},
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Wajib"])}
    }
  },
  "department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepala Sekolah"])},
    "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutan"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Department"])},
    "detailDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Department"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Department"])},
    "placeholder": {
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kepala Sekolah"])},
      "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Urutan"])}
    }
  },
  "permissionreason": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Izin"])},
    "reasonDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Alasan"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Alasan"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Alasan"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tingkat"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tingkat"])},
    "detailLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tingkat"])}
  },
  "semester": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Semester"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Semester"])},
    "detailSemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Semester"])}
  },
  "studytime": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Sekolah"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jam"])},
    "isRecess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang Istirahat?"])}
  },
  "schoolidentity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitas Sekolah"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEADER"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGO"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat 2"])},
    "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat 3"])},
    "address4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat 4"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama File"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "placeholder": {
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan URL Website"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Email"])},
      "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat1"])},
      "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat2"])},
      "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat3"])},
      "address4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat4"])},
      "telp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Telp1"])},
      "telp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Telp2"])},
      "telp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Telp3"])},
      "telp4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Telp4"])},
      "fax1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Fax1"])},
      "fax2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Fax2"])},
      "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama File"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Library"])}
    }
  },
  "comment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Pembelajaran"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajaran"])},
    "gradecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai & Komentar"])},
    "comment_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 1"])},
    "comment_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar 2"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Komentar"])},
    "editComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Komentar"])},
    "detailComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Komentar"])}
  },
  "major": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "min_major_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Major Subject"])},
    "min_cross_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Cross Subject"])},
    "addMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jurusan"])},
    "editMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Jurusan"])},
    "detailMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "placeholder": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])},
      "min_major_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Jurusan Subject"])},
      "min_cross_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Cross Subject"])}
    }
  },
  "majordate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kegiatan"])},
    "batch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelombang"])},
    "department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas Waktu"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Berakhir"])},
    "addMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Tanggal"])},
    "editMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tanggal"])},
    "detailMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tanggal"])},
    "placeholder": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Department"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Tingkat"])},
      "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Gelombang"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Batas Waktu"])}
    }
  },
  "majorSubject": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek Jurusan"])},
    "major_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
    "level_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "parallel_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek Paralel"])},
    "addMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Subjek Jurusan"])},
    "editMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Subjek Jurusan"])},
    "detailMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Subjek Jurusan"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "placeholder": {
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Jurusan"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Level"])},
      "parallel_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Subjek Paralel"])}
    }
  },
  "majorLevel": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat Jurusan"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Ajaran"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "web_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Web"])},
    "min_major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan Wajib"])},
    "min_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selain Jurusan"])},
    "addMajorLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Tingkat Jurusan"])},
    "editMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tingkat Jurusan"])},
    "detailMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tingkat Jurusan"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])}
  },
  "newbatchregis": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendaftaran Batch Baru"])},
    "batchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrasi Batch"])},
    "batchPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awalan Batch"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "placeholder": {
      "batchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk Proses"])},
      "batchPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk Prefix"])}
    }
  },
  "candidatesgroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok Calon"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok"])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipenuhi"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk Grup"])}
    }
  },
  "students": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siswa"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murid"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orang tua"])},
    "father": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayah"])},
    "mother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibu"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pribadi"])},
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Siswa"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok"])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipenuhi"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor kartu identitas"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Grup"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nomor kartu identitas"])}
    },
    "siswaorcalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siswa atau Calon Siswa"])}
  },
  "teacher": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guru"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "subtitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guru Pengganti"])},
    "teacherrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran Guru"])},
    "teacherextracuricular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstrakurikuler Guru"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Peran"])}
    }
  },
  "gradingaspects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek Penilaian"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Aspek Grading"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singkatan"])},
    "assignmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Tugas"])},
    "criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriteria"])},
    "gradingrule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan Grading"])},
    "nmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Minimal"])},
    "nmax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Maksimal"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading"])},
    "finalscorerule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan Skor Akhir"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMC"])},
    "portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagian"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Peran"])}
    }
  },
  "assignmentType": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek"])}
  },
  "gradingrule": {
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Aturan Penilaian"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Aturan Penilaian"])}
  },
  "finalscorerule": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "asstype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Penugasan"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bobot ( % )"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat"])},
    "asweight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penugasan & Bobot"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
  },
  "siblings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudara Mahasiswa"])},
    "isregis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terdaftar sebagai mahasiswa?"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekolah"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Sekolah"])}
    }
  },
  "syllabus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silabus"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Silabus"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Silabus"])},
    "detailNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Silabus No."])},
    "kd1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurikulum Topik1"])},
    "kd2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurikulum Topik2"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator"])},
    "warningSemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperkenankan menyalin pada semester yang sama"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik tombol Cari di atas untuk menampilkan Silabus"])},
    "secondInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik salah satu Kompetensi Inti di sebelah kiri untuk melihat Kompetensi Dasar"])}
  },
  "timeplan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rencana Waktu Pengajaran"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Silabus"])},
    "detailNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Silabus No."])},
    "cambridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silabus Cambridge"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator"])},
    "topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topik"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])},
    "warningSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sesi Tidak Cocok"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik tombol Cari di atas untuk menampilkan Data Rencana Waktu Pengajaran"])}
  },
  "lessonplan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rencana Belajar"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Rencana Belajar"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber Rencana Belajar"])},
    "sourcee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
    "subTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema Cadangan"])},
    "totalProsem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rencana Waktu Mengajar Total Sesi"])},
    "totalRpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sesi Rencana Belajar"])},
    "totalMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertemuan Total"])},
    "teachingMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahan Pendidikan"])},
    "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertemuan"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode"])},
    "sessionn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alokasi Waktu"])},
    "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
    "mainlesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajaran Utama"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembukaan"])},
    "mainActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifitas Utama"])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutupan"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik tombol Cari di atas untuk menampilkan Rencana Pelajaran"])},
    "secondInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik salah satu Rencana Pelajaran di sebelah kiri"])},
    "assignmentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Tugas"])}
  },
  "teacherjournal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal Guru"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas Detail"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas Siswa"])},
    "infal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infal"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentar"])},
    "firstHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jam Pertama"])},
    "lastHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jam Terakhir"])}
  },
  "coa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "nameForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Mata Anggaran"])},
    "addName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan mata anggaran..."])},
    "addRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan catatan..."])},
    "addCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kode..."])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kategori..."])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Gagal Dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Berhasil Ubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Berhasil Dihapus"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tidak dapat dihapus karena data ini sudah terhubung"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Tidak Ditemukan"])}
  },
  "financialyear": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "addDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan deskripsi..."])},
    "addFinyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan tahun buku..."])},
    "addInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan no tagihan..."])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tagihan"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Membuat Tahun Keuangan"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan Berhasil Diperbarui"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan Berhasil Dihapus"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tidak dapat dihapus karena data ini sudah terhubung dengan jurnal"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Keuangan Tidak Ditemukan"])}
  },
  "settingemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan Email Template"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Email Diperbarui"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Email Tidak Ditemukan"])}
  },
  "vacode": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA"])},
    "vacompanycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Perusahaan VA"])},
    "vasubcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Sub Perusahaan VA"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cara Pembayaran"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Membuat VA"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Diperbarui"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Dihapus"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA Tidak Ditemukan"])}
  },
  "settingodoo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Odoo"])},
    "dbname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Database"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "companyname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "syncschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal Sinkronisasi"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Odoo Diperbarui"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Odoo Tidak Ditemukan"])}
  },
  "settingschool": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Sekolah"])},
    "schoolname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sekolah"])},
    "taxno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tax"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambar"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Sekolah Diperbarui"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Sekolah Tidak Ditemukan"])}
  },
  "templateannouncement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Announcement"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acara"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek Email"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Pembuka"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Isi"])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Penutup"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengumuman Web"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari Peringatan"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat pada"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui pada"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat oleh"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui oleh"])},
    "glossary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glosarium"])}
  },
  "templateemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template"])},
    "tob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Bill"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuka"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi"])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selang Waktu Pengiriman"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai Email Dari"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
    "daybefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari Sebelum Tanggal Jatuh Tempo"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Pengiriman"])},
    "typeBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pembayaran"])},
    "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran"])},
    "addcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan alamat email..."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek"])},
    "addSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan subjek..."])},
    "addHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pembuka"])},
    "addBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih isi"])},
    "addFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih penutup"])},
    "addBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jenis pembayaran"])},
    "addDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai Email Dari"])},
    "addInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selang waktu pengiriman"])},
    "addTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih waktu pengiriman"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petunjuk: Tulis emailnya, pisahkan dengan tanda (;) tanpa spasi jika penerimanya lebih dari 1 (satu)!"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Gagal Dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Berhasil Ubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Berhasil Dihapus"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templat Email Tidak Ditemukan"])}
  },
  "typesofincome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan"])},
    "accountCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Akun"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritas"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Berulang"])},
    "fine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalti"])},
    "ispct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Persen"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cicilan"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selang"])},
    "maxTransInstal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenor Cicilan Maks"])},
    "maxTrans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Maks"])},
    "maxFine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Denda Maks"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
    "receivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piutang"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diskon"])},
    "prepaidincome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan diterima dimuka"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Jenis Pendapatan"])},
    "trgroup_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Grup Transaksi"])},
    "trgroup_waiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keringanan Grup Transaksi"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan berhasil dihapus."])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan tidak ditemukan."])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tidak bisa dihapus karena sudah digunakan."])},
    "notifCopySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan yang Disalin"])},
    "notifCopyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Menyalin Jenis Pendapatan"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan yang Diciptakan"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Membuat Jenis Pendapatan"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan Diperbarui"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan Tidak Ditemukan"])}
  },
  "sendemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Siaran"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Tagihan Bulan"])},
    "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan"])},
    "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Ajaran"])},
    "invoiceemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Tagihan"])},
    "receiptemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Kuitansi"])},
    "publishinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan Faktur"])},
    "publishpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan Pembayaran"])},
    "publishemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerbitkan"])},
    "unpublishedemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan publikasi"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
    "notifSendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Email Berhasil"])},
    "notifSendNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ayah atau ibu anak ini tidak ada"])},
    "notifSendFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap aktifkan template email yang sesuai"])},
    "notifSendSelectBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silahkan pilih siswa atau calon siswa"])},
    "notifSendSelectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silakan pilih tanggal"])},
    "notifPublishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Menerbitkan"])},
    "notifPublishFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Menerbitkan"])},
    "notifUnpublishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Batalkan publikasi"])},
    "notifUnpublishFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Batalkan publikasi"])}
  },
  "income": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
    "typeJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal"])},
    "billNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosess"])},
    "transNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Transaksi"])},
    "paymentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pembayaran"])},
    "waiverNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Keringanan"])},
    "typeIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan"])},
    "dp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uang Muka"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
    "installmentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Angsuran"])},
    "installmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenor Angsuran"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diskon"])},
    "billDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Tagihan"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas waktu"])},
    "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denda"])},
    "finePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Denda"])},
    "remainingPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran yang tersisa"])},
    "outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisa Bayar"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
    "payDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pembayaran"])},
    "waiverDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal keringanan"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membayar"])},
    "payValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Pembayaran"])},
    "waiverValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Keringanan"])},
    "generateva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate VA"])},
    "uploadva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload VA"])},
    "addList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan ke daftar pembayaran"])},
    "addDept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih departemen"])},
    "addFinyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tahun buku"])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kategori"])},
    "addIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pendapatan"])},
    "genInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Tagihan"])},
    "createInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Tagihan"])},
    "copyInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Tagihan"])},
    "createPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Pembayaran"])},
    "feewaiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keringanan Pembayaran"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Invoice Merged"])},
    "btnmerge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge"])},
    "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tingkat"])},
    "addClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kelas"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Aktif"])},
    "addBatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih gelombang"])},
    "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kelompok"])},
    "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelombang"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "regNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Registrasi"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No VA"])},
    "mobileNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Handphone"])},
    "telpNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Telepon"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan"])},
    "addJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jurnal"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat"])},
    "addInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tagihan"])},
    "inputTrans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan no transaksi..."])},
    "inputValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan nilai..."])},
    "inputDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan diskon..."])},
    "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan jumlah angsuran..."])},
    "inpurDp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan uang muka..."])},
    "inputInsVal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan nilai angsuran..."])},
    "payForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Pembayaran"])},
    "feewaiverForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Keringanan Pembayaran"])},
    "addPaay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Pembayaran"])},
    "addAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih akun"])},
    "acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
    "bankorcash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank / Cash"])},
    "addbankorcash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Bank / Cash"])},
    "advanceincome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penghasilan di Muka"])},
    "incomejournaldate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Jurnal Pendapatan"])},
    "allinv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Faktur"])},
    "outstandingreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Outstanding"])},
    "pdfprotector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebagai Lampiran"])},
    "notifPaymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Dibuat"])},
    "notifPaymentSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Email Gagal"])},
    "notifPaymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Membuat Pembayaran"])},
    "notifPaymentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Diperbarui"])},
    "notifPaymentNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Tidak Ditemukan"])},
    "notifPaymentDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Dihapus"])},
    "notifGenerateDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal faktur tidak boleh kurang dari Tahun Keuangan"])},
    "notifGenerateInvDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Faktur tidak boleh melebihi Tanggal Akhir Tahun Keuangan"])},
    "notifGenerateSucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan Akrual Dihasilkan"])},
    "notifGenerateExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktur sudah ada"])},
    "notifGenerateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Menghasilkan Penghasilan"])},
    "notifGenerateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tagihan yang Dihasilkan"])},
    "notifCopySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Disalin"])},
    "notifCopyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Menyalin Penghasilan"])},
    "notifAddValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan nilainya terisi."])},
    "notifAddJournalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Jurnal Pendapatan tidak boleh kurang dari tanggal Faktur."])},
    "notifAddInvDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal faktur tidak boleh kurang dari Tahun Keuangan."])},
    "notifAddInvDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Faktur tidak boleh melebihi Tanggal Akhir Tahun Keuangan."])},
    "notifAddDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Jatuh Tempo tidak boleh kurang dari tanggal Faktur."])},
    "notifAddTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenor tidak boleh lebih dari."])},
    "notifAddExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktur sudah ada."])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Dibuat."])},
    "notifAddDateLessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Jurnal Pendapatan tidak boleh kurang dari tanggal Faktur."])},
    "notifAddInvDateCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Faktur harus dalam periode tahun buku."])},
    "notifAddDueDateCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Jatuh Tempo harus dalam periode tahun buku."])},
    "notifAddSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Kandidat Dibuat."])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Diperbarui."])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Tidak Ditemukan."])},
    "notifEditSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Kandidat Diperbarui."])},
    "notifEditNotFoundCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Kandidat Tidak Ditemukan."])},
    "notifEditFailedCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktur ini sudah ada turunannya, tidak bisa diubah!."])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Dihapus."])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Tidak Ditemukan."])},
    "notifDeleteSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Kandidat Dihapus."])},
    "notifDeleteFailedCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrual Pendapatan Kandidat Tidak Ditemukan."])},
    "notifJurnalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal jurnal terbalik tidak boleh lebih awal dari tanggal faktur"])},
    "notifMergeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merged Invoice Berhasil"])},
    "notifMergeFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merged Invoice Gagal"])},
    "notifMergeSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor VA harus sama"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran"])}
  },
  "uploadva": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil diunggah"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal diunggah"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudah pernah diunggah"])}
  },
  "setting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengizinkan"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu SFTP"])}
  },
  "journal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal Umum"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Jurnal"])},
    "noJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Journal"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Transaksi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan"])},
    "akunName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Akun"])},
    "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
    "Officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Semua"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Berhasil Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Gagal Dibuat"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Berhasil Diubah"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Tidak Ditemukan"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Gagal Dihapus"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal Tidak Ditemukan"])},
    "endlessStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal akhir tidak boleh kurang dari tanggal mulai"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cek Odoo Berhasil"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cek Odoo Gagal"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencarian"])},
    "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "semua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
    "transaksi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterangan"])},
    "no_jurnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Jurnal"])},
    "belum_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Sync"])},
    "sudah_syns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudah Syns"])},
    "belum_validasi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Validasi"])},
    "syncdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Sinkronisasi"])},
    "reversingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membalikkan Tanggal"])},
    "invoiceno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor faktur"])},
    "invoicedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal faktur"])},
    "reversingjurnaldate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membalikkan Tanggal Jurnal"])},
    "reversingerp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membalikkan ERP"])}
  },
  "general": {
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum Dibuat"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal Membuat Umum"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Umum Diperbarui"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelan Umum Tidak Ditemukan"])}
  },
  "va": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memilih"])},
    "treasury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbendaharaan"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pembayaran"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Pembayaran"])},
    "fileDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pengajuan"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diunggah"])},
    "paymentdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Pembayaran"])}
  },
  "schedule": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal"])}
  },
  "presence": {
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
  },
  "day": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu"])}
  },
  "confirmDelete": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Delete"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakin ingin menghapus data ini?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin akan kirim ulang data ini?"])}
  },
  "confirmPublish": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakin ingin memublikasikan data ini?"])},
    "messageun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakin ingin membatalkan publikasi data ini?"])}
  },
  "schooltime": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu sekolah"])}
  },
  "workunit": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit kerja"])},
    "sortingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "calOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghitung Lembur"])}
  },
  "workinghour": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jam kerja"])},
    "sortingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "memberperiodically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota Secara Berkala"])},
    "calOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghitung Lembur"])}
  },
  "shift": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menggeser"])}
  },
  "position": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])}
  },
  "certification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikasi"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembaga"])},
    "occurUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi Sampai"])}
  },
  "family": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluarga"])},
    "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungan"])},
    "isEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Karyawan?"])},
    "isStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Mahasiswa?"])}
  },
  "leave": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meninggalkan"])},
    "requesrt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meminta"])},
    "reqLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minta Cuti"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimal"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satuan"])},
    "salaryDeduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengurangan Gaji"])},
    "atleastWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setidaknya bekerja untuk"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "reqLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minta Cuti"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimal"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satuan"])},
    "salaryDeduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengurangan Gaji"])},
    "atleastWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setidaknya bekerja untuk"])}
  },
  "outstanding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding"])},
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Type"])},
    "cutoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Off Date"])},
    "receivableage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umur Piutang (Hari)"])},
    "tuitionfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uang Sekolah"])}
  },
  "araging": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Aging"])}
  },
  "outbox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak Keluar"])},
    "detil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Kotak Keluar"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluran"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penerima"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengirim"])},
    "send_scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal Kirim"])},
    "send_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Kirim"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diulang"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil kirim ulang"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal kirim ulang"])}
  },
  "sent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak Terkirim"])},
    "detil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Kotak Terkirim"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluran"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penerima"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengirim"])},
    "send_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Kirim"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diulang"])}
  },
  "batas ulang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------------------------------------------"])},
  "finance": {
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])},
      "btnAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])}
    },
    "sidebar": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "master": {
        "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
        "finYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Buku"])},
        "coa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
        "emailTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "journalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Jurnal"])},
        "vaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode VA"])},
        "vaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Siswa"])},
        "transaction": {
          "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi"])},
          "transType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Transaksi"])},
          "transGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Transaksi"])}
        }
      },
      "incomes": {
        "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
        "incType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pendapatan"])},
        "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendapatan"])},
        "uploadVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah VA"])},
        "arOutstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding"])},
        "arAging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Aging"])},
        "genPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitung Denda"])},
        "genVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat VA"])},
        "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Email"])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagihan"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran"])},
        "boardInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boardcast Info"])}
      },
      "journal": {
        "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal"])},
        "importjournalentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impor Entri Jurnal"])},
        "generalJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal Umum"])},
        "reversingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membalikkan Jurnal"])}
      },
      "email": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "sentBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak Terkirim"])},
        "outBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak keluar"])}
      },
      "settings": {
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
        "setOdoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Odoo"])},
        "setEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Email"])},
        "setSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Sekolah"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Umum"])}
      },
      "report": {
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan"])},
        "outArSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding AR Summary"])},
        "financialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Keuangan"])},
        "paySummart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringkasan Pembayaran"])},
        "byDeptClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdasarkan Dept & Kelas"])},
        "byJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdasarkan Jenis Jurnal"])}
      }
    },
    "financialYear": {
      "addFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Tahun Buku"])},
      "editFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Tahun Buku"])},
      "detailFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Tahun Buku"])},
      "add": {
        "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen"])},
        "plcDept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan departemen"])},
        "invPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tagihan"])},
        "plcInvPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan no tagihan"])},
        "plcFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan tahun buku"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Berakhir"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
        "plcDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan deskripsi"])}
      }
    },
    "coa": {
      "addCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Akun"])},
      "editCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Akun"])},
      "detailCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Akun"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "plcCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kategori"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
        "plcCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kode"])},
        "coaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Mata Anggaran"])},
        "plcCoaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan mata anggaran"])},
        "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
        "plcRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan catatan"])}
      }
    },
    "scheduletime": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal"])},
      "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Jadwal"])},
      "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jadwal"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Jadwal"])}
    },
    "tempEmail": {
      "addTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Templat"])},
      "editTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Templat"])},
      "detailTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Templat"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "typeJournal": {
      "addJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jurnal"])},
      "editJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Jurnal"])},
      "detailJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Jurnal"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "va": {
      "addVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah VA"])},
      "editVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah VA"])},
      "detailVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Detil"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      }
    },
    "vaCode": {
      "addVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Kode VA"])},
      "editVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Kode VA"])},
      "detailVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Kode VA"])},
      "selectCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Akun"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "vaStudent": {
      "addVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah VA Siswa"])},
      "addVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah VA Calon Siswa"])},
      "editVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah VA Siswa"])},
      "editVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah VA Calon Siswa"])},
      "detailVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil VA Siswa"])},
      "detailVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil VA Calon Siswa"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "transGroup": {
      "addTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Grup Transaksi"])},
      "editTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Grup Transaksi"])},
      "detailTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Grup Transaksi"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "transType": {
      "addTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Transaksi"])},
      "editTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Jenis Transaksi"])},
      "detailTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Jenis Transaksi"])},
      "add": {
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Peran"])}
      }
    },
    "typeIncome": {
      "addTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Pendapatan"])},
      "editTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Jenis Pendapatan"])},
      "datailTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Jenis Pendapatan"])},
      "copyTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Jenis Pendapatan"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "comboardcategory": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Updated By"])}
    }
  },
  "comboarduser": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard User"])},
    "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])}
  },
  "comboardrecipients": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Recipients"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Pada"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation"])},
    "placeholder": {
      "userID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Id"])}
    }
  },
  "comboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard"])}
  },
  "attachment": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penugasan"])},
    "comboard_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "file_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 1"])},
    "file_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 2"])},
    "file_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 3"])},
    "file_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 4"])},
    "file_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 5"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat pada"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui Oleh"])},
    "placeholder": {
      "comboard_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan ID"])}
    }
  },
  "hrd": {
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai tanggal"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari tanggal"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Formulir Periode"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Periode"])},
    "detailperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode Detil"])},
    "hrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode Data"])}
  },
  "assigntypes": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas Jenis Skor"])},
    "subject_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siswa"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Assignment Type Score Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type Score Form"])},
    "detailperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Assignment Type Score"])}
  },
  "branch": {
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabang"])},
    "school_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekolah"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "editbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Branch"])},
    "addbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Form"])},
    "detailbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Branch"])}
  },
  "settingtimeout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Seleksi Mayor & Lintas Mayor"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu habis"])},
    "persen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat Distribusi"])}
  },
  "modul": {
    "modul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul Utama"])},
    "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Penerimaan"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrasi"])},
    "subjectselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemilihan Mata Pelajaran"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pemilihan Minat Ekstrakurikuler"])}
  },
  "studentdoc": {
    "docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Dokumen"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])}
  },
  "holiday": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari Libur"])},
    "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Akhir"])},
    "addholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Tambah Hari Libur"])},
    "detailholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Detil Hari Libur"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Hari Libur"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sekolah"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Sekolah"])}
    }
  },
  "facility": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber Daya Manusia"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuantiti"])},
    "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satuang Alat Ukur ?"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "is_hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Sumber Daya Manusia ?"])},
    "detailholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Detil Hari Libur"])},
    "addfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Tambah Fasilitas"])},
    "editfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Ubah Fasilitas"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sekolah"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Category"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Skala"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Masukan Nama Fasilitas"])}
    }
  },
  "facilityCharge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
    "facility_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Fasilitas"])},
    "facility_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi Fasilitas"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
    "replacement_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya Ganti"])},
    "is_hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber Daya Manusia"])},
    "detailfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Biaya Ganti"])},
    "addfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Biaya Ganti"])},
    "editfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Biaya Ganti"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sekolah"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert School"])},
      "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kondisi"])},
      "charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Biaya Ganti"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan List Harga"])}
    }
  },
  "room": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Ruangan"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Ruangan"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajer Ruangan"])},
    "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Gedung"])},
    "branch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Cabang"])},
    "owner_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pemiliki"])},
    "room_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Ruangan"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasitas"])},
    "lattitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lattitude"])},
    "longtitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "altitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude"])},
    "Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasitas"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
    "room_manager_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Manajer Ruangan"])},
    "room_manager_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Manajer Ruangan"])},
    "room_manager_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username Manajer Ruangan"])},
    "replacement_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarif Biaya Ganti"])},
    "room_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruangan Induk"])},
    "sort_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Sortir"])},
    "detailOwnerroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Pemilik Ruangan"])},
    "detailActivityroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Aktivitas Ruangan"])},
    "addroomActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tambah Aktivitas Ruangan"])},
    "editroomActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Ubah Room Activity"])},
    "addroomOperating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tambah Pengorperasian Ruangan"])},
    "editroomOperating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Ubah Room Pengorperasian Ruangan"])},
    "detailOperatingroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Pengorperasian Ruangan"])},
    "addroomOccupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tambah Ketersediaan Ruangan"])},
    "editroomOccupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Ubah Ketersediaan Ruangan"])},
    "detailOccupationroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Ketersediaan Ruangan"])},
    "detailRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Ruangan"])},
    "addroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tambah Ruangan"])},
    "edituroom  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Ubah Ruangan"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sekolah"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
    "RoomOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Pemilik Ruangan"])},
    "addroomOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Tambah Pemilik Ruangan"])},
    "editroomOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Ubah Pemilik Ruangan"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Sekolah"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Cabang"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Gedung"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Manajer"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ruangan"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Aktivitas"])}
    }
  },
  "roomOwner": {
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List data Ruangan"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Pemilik Ruangan"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ruangan"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pemilik Ruangan"])}
    }
  },
  "tenant": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Penyewa"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Penyewa"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Penyewa"])},
    "PIC_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person In Charge Name"])},
    "PIC_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon Person In Charge"])},
    "PIC_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Person In Charge"])},
    "PIC_Wa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp Nomor Person In Charge"])},
    "FacilityPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Harga Fasilitas"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activity"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Room"])}
    }
  },
  "timeOper": {
    "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Kegiatan"])},
    "activity_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Kegiatan"])},
    "is_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Tersedia di Hari Liburan ?"])},
    "is_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Tersedia di Akhir Pekan ?"])},
    "is_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Tersedia di Hari Kerja ?"])},
    "start_time_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai Saat Liburan"])},
    "start_time_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai Saat Akhir Pekan"])},
    "start_time_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai Saat Hari Kerja"])},
    "end_time_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai Saat Liburan"])},
    "end_time_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai Saat Akhir Pekan"])},
    "end_time_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai Saat Hari Kerja"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ruangan"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pemilik Ruangan"])}
    }
  },
  "batas ulang v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------ Project ---------------------------"])},
  "project": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projek"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Projek"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Projek"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Project"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabang"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latar belakang"])},
    "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuan"])},
    "initiator1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inisiator 1"])},
    "initiator2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inisiator 2"])},
    "initiation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Inisiasi"])},
    "weight_sow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat SOW"])},
    "weight_aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat Aspek"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Cabang..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenis..."])},
      "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Latar belakang..."])},
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tujuan..."])},
      "initiator1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih..."])},
      "initiator2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih..."])},
      "initiation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal Inisiasi..."])},
      "weight_sow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Berat SOW..."])},
      "weight_aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Berat Aspek..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "idsetting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan ID"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Kode Proyek: "])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Kode MOM: "])},
    "change_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Change Request: "])},
    "blacklisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyetuju Daftar Hitam Vendor :"])},
    "whitelisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyetuju Daftar Putih Vendor: "])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Digit Kode Proyek"])},
      "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Digit Kode MOM"])},
      "change_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Digit Change Request"])},
      "blacklisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pengguna"])},
      "whitelisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pengguna"])}
    }
  },
  "activityformeeting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinkronisasi Sistem Booking"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Aktivitas Rapat: "])},
    "placeholder": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenis..."])}
    }
  },
  "documentexpiredreminder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengingat Kedaluwarsa Dokumen"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
    "placeholder": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Hari..."])}
    }
  },
  "vendortypevendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Vendor Type Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Vendor Type Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Type Vendor"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "vendor_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Vendor..."])},
      "vendor_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenis Vendor..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "vendorstatus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Status"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Vendor Status"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Vendor Status"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Status"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
    "approved_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disetujui Oleh"])},
    "approved_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disetujui Tanggal"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Vendor"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Status"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alasan"])},
      "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pengguna"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "projecttype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Projek"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Projek"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Jenis Projek"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Jenis Projek"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kode..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "legaldocument": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Dokumen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Legal Dokumen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Legal Dokumen"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Legal Dokumen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "has_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memiliki Tanggal kadaluwarsa"])},
    "monitor_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pantau Tanggal kadaluwarsa"])},
    "is_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wajib"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah File"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "vendortype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Jenis Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Jenis Vendor"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "aspecttype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Aspek"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Aspek"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Jenis Aspek"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Jenis Aspek"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Aspek..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "issuetype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Isu"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Isu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Jenis Isu"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Jenis Isu"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "project_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Projek"])},
    "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isu"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenis..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "vendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panggilan"])},
    "sync_with_erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disinkronkan dengan ERP"])},
    "sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Sinkronisasi"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
    "tax_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Pajak"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Terdaftar"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Panggilan..."])},
      "sync_with_erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disinkronkan dengan ERP..."])},
      "sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Sinkronisasi..."])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat..."])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kota..."])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Provinsi..."])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kode Pos..."])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Telepon..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Email..."])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan WhatsApp..."])},
      "tax_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nomor Pajak..."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Catatan..."])},
      "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Terdaftar..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "pitchingAspect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek Presentasi"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "aspectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Aspek Presentasi"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Aspek Presentasi"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Project"])},
      "aspectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Aspek"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Deskripsi"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Berat"])},
      "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Aspek"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "pitchingAspectDetail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Aspek Presentasi"])},
    "pitchingAspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek Presentasi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skore"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Detail Aspek Presentasi"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Detail Aspek Presentasi"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "pitchingAspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Aspek Presentasi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Deskripsi"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Skore"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "projectVendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Project"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah vendor"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "projectteamrole": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projek Tim Role"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Projek Tim Role"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Projek Tim Role"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Projek Tim Role"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "used_in_procurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digunakan Dalam Pengadaan"])},
    "used_in_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digunakan Dalam Produksi"])},
    "ist_third_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Peran..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "contactperson": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Person"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Kontak Person"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Kontak Person"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Kontak Person"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
    "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Email..."])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Posisi..."])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Whatsapp..."])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Phone..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "vendorlegaldoc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen Legal Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Dokumen Legal Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Dokumen Legal Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Dokumen Legal Vendor"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor"])},
    "issuance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terbit"])},
    "exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kadaluwarsa"])},
    "issuance_institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lembaga penerbit"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen Logal"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
    "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor"])},
      "issuance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terbit"])},
      "exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kadaluwarsa"])},
      "issuance_institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lembaga penerbit"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
      "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Dokumen"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "changerequest": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan perubahan"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Permintaan perubahan"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Permintaan perubahan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Permintaan perubahan"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inisiator"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projek"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "change_request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Permintaan perubahan"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "impact_to_time_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dampak terhadap waktu"])},
    "impact_to_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dampak terhadap lingkup"])},
    "impact_to_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dampak terhadap biaya"])},
    "impact_to_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dampak terhadap kualitas"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Initiator"])},
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Projek"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nomor"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Catatan"])},
      "change_request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Tanggal Permintaan perubahan"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi"])},
      "impact_to_time_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan dampak waktu"])},
      "impact_to_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan dampak ruang lingkup"])},
      "impact_to_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan dampak biaya"])},
      "impact_to_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan dampak kualitas"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "changerequestapp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikasi Permintaan Perubahan"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Permintaan Perubahan"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Permintaan Perubahan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Permintaan Perubahan"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projek"])},
    "change_request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "approver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyetuju"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Tanggal"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Tanggal"])},
    "placeholder": {
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Initiator"])},
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Projek"])},
      "change_request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Permintaan"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Status"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alasan"])},
      "approver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Penyetuju"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "expectedscope": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingkup yang Diharapkan"])},
    "scopeofwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang Lingkup Kerja"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Maksimal"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Mininum"])},
    "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepentingan"])},
    "expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diharapkan Manajemen"])},
    "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komitmen Vendor"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktual"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Ruang Lingkup Kerja"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Ruang Lingkup Kerja"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi..."])},
      "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kepentingan..."])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Skor Maksimal..."])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Skor Mininum..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "timeline": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal Waktu"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuat"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Dibuat"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perencanaan"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktual"])},
    "reasonofchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Perubahan"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "copy_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Jadwal"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Jadwal Waktu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Jadwal Waktu"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Versi..."])},
      "reasonofchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan Perubahan..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "internalteam": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tim Internal"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "active_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Aktif"])},
    "inactive_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Nonaktif"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Tim Internal"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tim Internal"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "planning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perencanaan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Perencanaan"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Perencanaan"])},
    "task_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Tugas"])},
    "task_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas Induk"])},
    "task_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Tugas"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
    "allow_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan Pembayaran"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Melempar"])},
    "task_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persentase Tugas (%)"])},
    "progress_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persentase Kemajuan (%)"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "name_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Wakil"])},
    "start_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai - Selesai"])},
    "actual_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai Aktual"])},
    "actual_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai Aktual"])},
    "actual_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Aktual"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "paymentterm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketentuan Pembayaran"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Ketentuan Pembayaran"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Ketentuan Pembayaran"])},
    "purchase_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Pembelian"])},
    "purchase_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pembelian"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mata Uang"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuat"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Dibuat"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "deliverable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pengiriman"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pengiriman"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuat"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Dibuat"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "milestonedoc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Dokumen"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Dokumen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuat"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Dibuat"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "actual": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktual"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Aktual"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Aktual"])},
    "task_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Tugas"])},
    "task_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas Induk"])},
    "task_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Tugas"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "minuteofmeeting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapat"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Rapat"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Rapat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Rapat"])},
    "agenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai"])},
    "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "placeholder": {
      "agenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Agenda..."])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kode..."])},
      "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Tempat..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi..."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Status..."])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alasan..."])}
    }
  },
  "invitation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undangan"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapat"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "send_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim undangan"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Email..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama..."])}
    }
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapat"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Pengguna..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama..."])}
    }
  },
  "result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapat"])},
    "addResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Hasil"])},
    "placeholder": {
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan hasil..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan deskripsi..."])}
    }
  },
  "Rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal Ulang"])},
  "todo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Do"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projek"])},
    "pic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas Waktu"])},
    "complete_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangkuman Rapat"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "addTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Hasil"])},
    "reTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Hasil"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode"])},
    "placeholder": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Pengguna..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama..."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Status..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi..."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Catatan..."])}
    },
    "form": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
      "complete_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
    }
  },
  "logissue": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan Isu"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Catatan Isu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Catatan Isu"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Catatan Isu"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Proyek"])},
    "issue_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Isu"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penginisiasi"])},
    "raised_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diangkat"])},
    "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepentingan"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Proyek"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi"])},
      "issue_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tipe"])},
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Penginisiasi"])},
      "raised_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal"])},
      "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kepentingan"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "logissuedetail": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Detail Catatan Isu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Catatan Isu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Detail Catatan Isu"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Detail Catatan Isu"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Proyek"])},
    "log_issue_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan Isu"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi"])},
    "pic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota Tim 1"])},
    "pic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota Tim 2"])},
    "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Batas Waktu"])},
    "resolve_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Penyelesaian"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Proyek"])},
      "log_issue_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Catatan Isu"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Solusi"])},
      "pic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Anggota Tim 1"])},
      "pic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Anggota Tim 2"])},
      "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal Batas Waktu"])},
      "resolve_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal Penyelesaian"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Status"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Catatan"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "pitchingProject": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek Pemilihan"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyek"])},
    "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor"])},
    "pitching_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Pemilihan"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pemeriksa"])},
    "totalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Skor Total"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Catatan"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Proyek Pemilihan"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Proyek Pemilihan"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Proyek"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Vendor"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Catatan"])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Pemeriksa"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  },
  "pitchingProjectDetail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Proyek Pemilihan"])},
    "expScopeDtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingkup yang Diharapkan"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspek Lainnya"])},
    "aspectdtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Jawaban"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Catatan"])},
    "WeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Point"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Detail Proyek Pemilihan"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Detail Proyek Pemilihan"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Oleh"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat Pada"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Oleh"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diubah Pada"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Projek..."])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Vendor..."])},
      "expScopeDtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Lingkup yang Diharapkan..."])},
      "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Aspek Lainnya..."])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Skor..."])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Jawaban..."])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Berat..."])},
      "WeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Point..."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Catatan..."])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Ulang"])}
    }
  }
}