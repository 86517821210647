import { axiosEdu } from '@/axios/axios'
// import store from '.'
import { setHeaderToken, removeHeaderToken, setHeaderTokenEdu, removeHeaderTokenEdu } from '../utils/auth'
import CryptoJS from 'crypto-js'
export default {
  state: {
    isAuthenticated: false,
    user: null,
    student: null,
    parent: null,
    employee_id: null,
    moduleList: [],
    isActiveModule: ''
  },
  mutations: {
    set_user (state, data) {
      state.user = data
      state.isAuthenticated = true
    },
    set_booking (state, data) {
      state.user = data
      state.isAuthenticated = true
    },
    set_student (state, data) {
      state.student = data
      state.isAuthenticated = true
    },
    set_parent (state, data) {
      state.parent = data
      state.isAuthenticated = true
    },
    reset_student (state) {
      state.student = null
      state.isAuthenticated = false
    },
    reset_parent (state) {
      state.parent = null
      state.isAuthenticated = false
    },
    reset_user (state) {
      state.user = null
      state.isAuthenticated = false
    },
    reset_booking (state) {
      state.user = null
      state.isAuthenticated = false
    },
    set_moduleList (state, data) {
      state.moduleList = data
    },
    reset_moduleList (state) {
      state.moduleList = []
    },
    set_isActiveModule (state, data) {
      state.isActiveModule = data
    },
    reset_isActiveModule (state, data) {
      state.isActiveModule = ''
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    user (state) {
      return state.user
    },
    employee (state) {
      return state.employee_id
    },
    moduleList (state) {
      return state.moduleList
    }
  },
  actions: {
    login ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosEdu.post('auth/login/', data)
          .then(response => {
            const token = response.data.access_token
            console.log('token login => ', token)
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('username', response.data.user.username)
            localStorage.setItem('name', response.data.user.name)
            localStorage.setItem('roles', response.data.roles)
            localStorage.setItem('loginAs', 'administrator')

            localStorage.setItem('menu', response.data.get_menu)

            const encryptedMessage = CryptoJS.AES.encrypt(localStorage.getItem('roles'), 'amatra1609').toString()
            localStorage.setItem('role', encryptedMessage)

            setHeaderTokenEdu(token)
            setHeaderToken(token)
            commit('set_user', response.data.user)
            // store.dispatch('get_user', token)
            commit('set_moduleList', response.data.modules_code)
            localStorage.setItem('moduleList', JSON.stringify(response.data.modules_code))
            if (response.data.modules_code.length > 0) {
              commit('set_isActiveModule', response.data.modules_code[0].code)
              localStorage.setItem('isActiveModule', response.data.modules_code[0].code)
            }
            resolve(response)
          })
          .catch(err => {
            commit('reset_user')
            commit('reset_moduleList')
            commit('reset_isActiveModule')
            localStorage.removeItem('token')
            removeHeaderToken()
            reject(err)
          })
      })
    },
    async get_user ({ commit }) {
      if (!localStorage.getItem('token')) {
        return
      }
      try {
        // const response = await axiosEdu.get('users/' + localStorage.getItem('user_id'))
        // commit('set_user', response.data.data)
      } catch (error) {
        // commit('reset_user')
        // removeHeaderToken()
        // localStorage.removeItem('token')
        return error
      }
    },
    async validateToken ({ commit }, token) {
      // if (!token) {
      //   commit('reset_user')
      //   return false
      // }

      try {
        const response = await axiosEdu.post('auth/validate-token', null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        console.log('response validateToken', response)
        localStorage.setItem('token', token)
        localStorage.setItem('user_id', response.data.user.id)
        localStorage.setItem('username', response.data.user.username)
        localStorage.setItem('name', response.data.user.name)
        localStorage.setItem('roles', response.data.roles)
        localStorage.setItem('loginAs', 'administrator')

        localStorage.setItem('menu', response.data.get_menu)

        const encryptedMessage = CryptoJS.AES.encrypt(localStorage.getItem('roles'), 'amatra1609').toString()
        localStorage.setItem('role', encryptedMessage)

        setHeaderTokenEdu(token)
        setHeaderToken(token)
        commit('set_user', response.data.user)
        // store.dispatch('get_user', token)
        commit('set_moduleList', response.data.modules_code)
        localStorage.setItem('moduleList', JSON.stringify(response.data.modules_code))
        if (response.data.modules_code.length > 0) {
          commit('set_isActiveModule', response.data.modules_code[0].code)
          localStorage.setItem('isActiveModule', response.data.modules_code[0].code)
        }
        // resolve(response)
        // If token is valid, update user info in Vuex
        return true
      } catch (error) {
        // If token is invalid or expired, reset state and remove the token
        console.error('Token validation failed:', error.response ? error.response.data : error)
        // commit('reset_user')
        // Optionally handle token removal here if needed
        return false
      }
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('reset_user')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('checkall')
        localStorage.clear()
        removeHeaderTokenEdu()
        resolve()
      })
    }
  }
}
