<template>
  <div id="app">
    <div :class="class_var" v-if="!isAdminLayout">
      <router-view/>
    </div>

    <div v-else>
      <nav-bar :module="masterModule"/>

      <aside-menu :menu="sidemenu"/>

      <div
        class="transition-all duration-1000"
        :class="[isAsideMobileExpanded ? 'lg:pl-0' : 'lg:pl-64']"
        :style="[darkMode ? 'background-color: #121212;' : 'background-color: #f0f0f0;']"
      >
        <div class="pt-14" style="height: auto; min-height: calc(100vh - 32px);">
          <router-view/>
        </div>

        <footer-bar/>
      </div>
    </div>

    <vue-progress-bar />

    <overlay v-show="isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
  </div>
</template>

<script>
  /* eslint-disable */
  import { computed, ref, onMounted} from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n';
  import menu from '@/menu.js'
  import { useRouter } from 'vue-router'
  import NavBar from '@/components/NavBar'
  import AsideMenu from '@/components/AsideMenu'
  import FooterBar from '@/components/FooterBar'
  import Overlay from '@/components/Overlay'
  import '@tailwindcss/forms/dist/forms.min.css'
  import CryptoJS from 'crypto-js'

  export default {
    name: 'APP',

    components: {
      Overlay,
      FooterBar,
      AsideMenu,
      NavBar,
    },

    mounted () {
      this.$Progress.finish()
    },

    created () {
      this.$Progress.start()

      this.$router.afterEach((to, from) => {
        this.$Progress.finish()
      })
    },

    data: function () {
      return {
        isAdminLayout:false,
        sidemenu:[],
        class_var:'',
        userObject:Object,
        masterModule:[]
      }
    },

    watch: {
      $route(to, from) {
        if (to.matched.length && to !== from ) {
          this.isAdminLayout = this.checkRoleAdmin()
          this.userObject = this.userObj()
          this.sidemenu = this.mainmenu()
          this.masterModule = JSON.parse(localStorage.getItem('moduleList'))
        }
      }
    },

    methods: {
      userObj(){
        return  ''
      },

      mainmenu(){
        let menuStorage = localStorage.getItem('isActiveModule')
        const user_id = localStorage.getItem('user_id')
        const checkUser = ref(false)
        const checkUserFinance = ref(false)
        const checkUserTeacher = ref(false)
        const checkUserYayasan = ref(false)

        if(user_id !== null){
          const decryptedMessage = CryptoJS.AES.decrypt(localStorage.getItem('role'), 'amatra1609').toString(CryptoJS.enc.Utf8)
          if (decryptedMessage === 'ROLE_TEACHER') {
            checkUserTeacher.value = true
          }
          if (decryptedMessage === 'ROLE_ADMIN_FINANCE') {
            checkUser.value = true
          }
          if (decryptedMessage === 'ROLE_FINANCE') {
            checkUserFinance.value = true
          }
          if (decryptedMessage === 'ROLE_FOUNDATION') {
            checkUserYayasan.value = true
          }

          const myArray = decryptedMessage.split(',')
          for (let i = 0; i < myArray.length; i++) {
            if (myArray[i] === 'ROLE_TEACHER') {
              checkUserTeacher.value = true
            }
            if (myArray[i] === 'ROLE_ADMIN_FINANCE') {
              checkUser.value = true
            }
            if (myArray[i] === 'ROLE_FINANCE') {
              checkUserFinance.value = true
            }
            if (myArray[i] === 'ROLE_FOUNDATION') {
              checkUserYayasan.value = true
            }
          }
        }

        let listmenu = []
        /*
        if (menuStorage=='ACA') {
          if(checkUserTeacher.value){
            listmenu = menuTeacher
          }else{
            listmenu = menu
          }
        } else if (menuStorage=='FINA') {
          if(checkUserFinance.value){
            listmenu = menuFinance
          }
          if((checkUser.value || checkUserYayasan.value)){
            listmenu = menuFinanceAdm
          }
        } else if (menuStorage == 'HUCAP') {
          listmenu = menuEmployee
        } else if (menuStorage == 'COM') {
          listmenu = menuComboard
        } else if (menuStorage == 'BOOKING') {
          listmenu = menuBooking
        } else {
          if (['ADMISS', 'AD', 'REGIST', 'SUBJECT', 'CLUB'].includes(menuStorage)){
            listmenu = menuModule
          }
        }
        */

        if(localStorage.getItem('role')){
          listmenu = menu
        }

        return listmenu
      },

      checkRoleAdmin(){
        if(localStorage.getItem('loginAs') === 'administrator') {
          return true
        } else {
          return false
        }
      }
    },

    setup () {
      const router = useRouter()
      const { t } = useI18n({useScope: 'global'});
      const darkMode = computed(() => store.state.darkMode)
      const store = useStore()
      const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)
      const user = ref({})

      if (store.state.auth.user) {
        store.commit('user', {
          name: store.state.auth.user.name,
          email: store.state.auth.user.email,
          avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93'
        })

        user.value = store.state.auth.user
      }

      const isAsideLgActive = computed(() => store.state.isAsideLgActive)

      const overlayClick = () => {
        store.dispatch('asideLgToggle', false)
      }

      return {
        router,
        menu,
        isAsideLgActive,
        isAsideMobileExpanded,
        overlayClick,
        darkMode,
        t
      }
    }
  }
</script>
