import { createRouter, createWebHashHistory } from 'vue-router'
// import { ref } from 'vue'
import store from '../store/index'

import Home from '../views/Home'

const routes = [
  {
    meta: {
      title: 'Dashboard',
      requireLogin: true
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Login',
      fullScreen: true
    },
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'login' */ '../views/Login')
  },
  {
    meta: {
      title: 'Register',
      fullScreen: true
    },
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: 'register' */ '../views/Register')
  },
  {
    meta: {
      title: 'Change Password',
      fullScreen: true
    },
    path: '/change-password',
    name: 'Change Password',
    component: () => import(/* webpackChunkName: 'register' */ '../views/ChangePassword')
  },
  {
    meta: {
      title: 'Reset Password',
      fullScreen: true
    },
    path: '/resetpassword',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: 'register' */ '../views/ResetPassword')
  },
  {
    meta: {
      title: 'Admin Dashboard',
      requireLogin: true
    },
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: 'admin.index' */ '../views/admin/dashboard/index.vue')
  },

  // Setting
  {
    meta: {
      title: 'ID Setting',
      requireLogin: true
    },
    path: '/id-setting',
    name: 'id-setting',
    component: () => import(/* webpackChunkName: 'digitcode.index' */ '../views/admin/settings/digitcode/Index.vue')
  },
  {
    meta: {
      title: 'Activity For Meeting',
      requireLogin: true
    },
    path: '/activity-for-meeting',
    name: 'activity-for-meeting',
    component: () => import(/* webpackChunkName: 'digitcode.index' */ '../views/admin/settings/activityformeeting/Index.vue')
  },
  {
    meta: {
      title: 'Document Expired Reminder',
      requireLogin: true
    },
    path: '/document-expired-reminder',
    name: 'document-expired-reminder',
    component: () => import(/* webpackChunkName: 'digitcode.index' */ '../views/admin/settings/documentexpiredreminder/Index.vue')
  },

  // Project
  {
    meta: {
      title: 'Project Document',
      requireLogin: true
    },
    path: '/project-document',
    name: 'project-document',
    component: () => import(/* webpackChunkName: 'projecttype.index' */ '../views/admin/projectdocument/Index.vue')
  },
  {
    meta: {
      title: 'Project',
      requireLogin: true
    },
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: 'projecttype.index' */ '../views/admin/project/Index.vue')
  },
  {
    meta: {
      title: 'Minutes Of Meeting',
      requireLogin: true
    },
    path: '/mom',
    name: 'Minutes Of Meeting',
    component: () => import(/* webpackChunkName: 'projecttype.index' */ '../views/admin/mom/Index.vue')
  },
  {
    meta: {
      title: 'Log Issue',
      requireLogin: true
    },
    path: '/log-issue',
    name: 'log-issue',
    component: () => import(/* webpackChunkName: 'logisssue.index' */ '../views/admin/logissue/Index.vue')
  },
  {
    meta: {
      title: 'Log Issue Detail',
      requireLogin: true
    },
    path: '/log-issue-detail',
    name: 'log-issue-detail',
    component: () => import(/* webpackChunkName: 'logisssuedetail.index' */ '../views/admin/logissuedetail/Index.vue')
  },

  // Master
  {
    meta: {
      title: 'Project Type',
      requireLogin: true
    },
    path: '/project-type',
    name: 'project-type',
    component: () => import(/* webpackChunkName: 'projecttype.index' */ '../views/admin/projecttype/Index.vue')
  },
  {
    meta: {
      title: 'Legal Document',
      requireLogin: true
    },
    path: '/legal-document',
    name: 'legal-document',
    component: () => import(/* webpackChunkName: 'legaldoc.index' */ '../views/admin/legaldocument/Index.vue')
  },
  {
    meta: {
      title: 'Vendor Type',
      requireLogin: true
    },
    path: '/vendor-type',
    name: 'vendor-type',
    component: () => import(/* webpackChunkName: 'vendortype.index' */ '../views/admin/vendortype/Index.vue')
  },
  {
    meta: {
      title: 'Vendor',
      requireLogin: true
    },
    path: '/vendor',
    name: 'vendor',
    component: () => import(/* webpackChunkName: 'vendor.index' */ '../views/admin/vendor/Index.vue')
  },
  {
    meta: {
      title: 'Vendor Status',
      requireLogin: true
    },
    path: '/vendor-status',
    name: 'vendor-status',
    component: () => import(/* webpackChunkName: 'vendorstatus.index' */ '../views/admin/vendorstatus/Index.vue')
  },
  {
    meta: {
      title: 'Vendor Type Vendor',
      requireLogin: true
    },
    path: '/vendor-type-vendor',
    name: 'vendor-type-vendor',
    component: () => import(/* webpackChunkName: 'vendortypevendor.index' */ '../views/admin/vendortypevendor/Index.vue')
  },
  {
    meta: {
      title: 'Vendor Leverage Level',
      requireLogin: true
    },
    path: '/vendor-leverage-level',
    name: 'vendor-leverage-level',
    component: () => import(/* webpackChunkName: 'vendorleveragelevel.index' */ '../views/admin/vendorLeverageLevel/Index.vue')
  },
  {
    meta: {
      title: 'Aspect Type',
      requireLogin: true
    },
    path: '/aspect-type',
    name: 'aspect-type',
    component: () => import(/* webpackChunkName: 'aspecttype.index' */ '../views/admin/aspecttype/Index.vue')
  },
  {
    meta: {
      title: 'Issue Type',
      requireLogin: true
    },
    path: '/issue-type',
    name: 'issue-type',
    component: () => import(/* webpackChunkName: 'issuetype.index' */ '../views/admin/issuetype/Index.vue')
  },
  {
    meta: {
      title: 'Project Team Role',
      requireLogin: true
    },
    path: '/project-team-role',
    name: 'project-team-role',
    component: () => import(/* webpackChunkName: 'projectteamrole.index' */ '../views/admin/projectteamrole/Index.vue')
  },
  {
    meta: {
      title: 'Contact Person',
      requireLogin: true
    },
    path: '/contact-person',
    name: 'contact-person',
    component: () => import(/* webpackChunkName: 'contactperson.index' */ '../views/admin/contactperson/Index.vue')
  },
  {
    meta: {
      title: 'Vendor Legal Doc',
      requireLogin: true
    },
    path: '/vendor-legal-doc',
    name: 'vendor-legal-doc',
    component: () => import(/* webpackChunkName: 'vendorlegaldoc.index' */ '../views/admin/vendorlegaldoc/Index.vue')
  },
  {
    meta: {
      title: 'Template Email',
      requireLogin: true
    },
    path: '/template-email',
    name: 'template-email',
    component: () => import(/* webpackChunkName: 'templateemail.index' */ '../views/admin/templateemail/Index.vue')
  },
  {
    meta: {
      title: 'Pitching Aspect',
      requireLogin: true
    },
    path: '/pitching-aspect',
    name: 'pitching aspect',
    component: () => import(/* webpackChunkName: 'pitchingAspect.index' */ '../views/admin/pitchingAspect/Index.vue')
  },
  {
    meta: {
      title: 'Project Pitching',
      requireLogin: true
    },
    path: '/project-pitching',
    name: 'Project Pitching',
    component: () => import(/* webpackChunkName: 'projectPitching.index' */ '../views/admin/projectPitching/Index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  },
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requireLogin)) {
//     const token = ref(to.query.token)
//     if (token.value) {
//       console.log('SSO Token detected:', token.value)
//       store
//         .dispatch('validateToken', token.value)
//         .then((valid) => {
//           console.log('valid', valid)
//           if (valid) {
//             console.log('Token is valid', to)
//             store.state.auth.isAuthenticated = true
//             next({ path: to.path })
//           } else {
//             console.log('Token is invalid or expired')
//           }
//         })
//         .catch((err) => console.error('Error during token validation:', err))
//       // Handle token validation and login logic here
//       next({ path: to.path }) // Allow access after token validation
//       return
//     }
//     const roles = localStorage.getItem('roles')
//     console.log('isAuthenticated', store.state.auth.isAuthenticated)
//     if (store.state.auth.isAuthenticated) {
//       const myArray = ['ROLE_FINANCE']
//       const roleExists = roles.split(',').some((value) => myArray.includes(value))
//       if (!roleExists) {
//         if (to.path === '/') {
//           next({ path: '/dashboard' })
//           return
//         }
//       }
//       next()
//       return
//     }

//     if (localStorage.getItem('user_id') !== null) {
//       const myArray = ['ROLE_FINANCE']
//       const roleExists = roles.split(',').some((value) => myArray.includes(value))
//       if (!roleExists) {
//         if (to.path === '/') {
//           next({ path: '/dashboard' })
//           return
//         }
//       }
//       next()
//       return
//     }

//     // if (localStorage.getItem('loginAs') === 'parent') {
//     //   next({ path: '/login-parent', query: { to: to.path } })
//     //   return
//     // } else if (localStorage.getItem('loginAs') === 'student') {
//     //   next({ path: '/login-student', query: { to: to.path } })
//     //   return
//     // } else if (localStorage.getItem('loginAs') === 'booking') {
//     //   next({ path: '/login-booking', query: { to: to.path } })
//     //   return
//     // } else {
//     //   next({ path: '/login', query: { to: to.path } })
//     //   return
//     // }
//     next({ path: '/login', query: { to: to.path } })
//     return
//   }

//   next()
// })
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin)) {
    const token = to.query.token
    const roles = localStorage.getItem('roles') || ''
    const userId = localStorage.getItem('user_id')
    const requiredRoles = ['ROLE_FINANCE'] // Define the roles required for access

    // Token validation
    if (token) {
      console.log('SSO Token detected:', token)
      try {
        const valid = await store.dispatch('validateToken', token)
        if (valid) {
          console.log('Token is valid')
          store.state.auth.isAuthenticated = true
          next() // Proceed to the requested route
          return
        } else {
          console.error('Token is invalid or expired')
        }
      } catch (err) {
        console.error('Error during token validation:', err)
      }
      next({ path: '/login', query: { to: to.path } })
      return
    }

    // Check authentication and roles
    if (store.state.auth.isAuthenticated || userId) {
      const roleExists = roles.split(',').some(role => requiredRoles.includes(role))
      if (!roleExists && to.path === '/') {
        next({ path: '/dashboard' }) // Redirect to dashboard if the user lacks required roles
        return
      }
      next() // Allow access
      return
    }

    // Redirect to login
    next({ path: '/login', query: { to: to.path } })
    return
  }

  next() // Allow access for routes that don't require login
})

export default router
